<template>
  <div class="stellar-objects view">
    <app-header :title="__('view.ingame.stellar_objects.title')"/>
    <progress-linear v-if="loading" :indeterminate="true"/>
    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <div class="control-bar__info">
        <div class="control-bar__info-title">Total:</div>
        <div class="control-bar__info-value">{{ pagination.total }}</div>
      </div>
      <text-field
          class="control-bar__search"
          v-model="searchTerm"
          :label="__('common.search_term')"
          max-length="50"
          :hide-details="true"
          :clearable="true"
          @keyup:enter="refreshDelayed(100)"
          @click:clear="refreshDelayed(100)"
      />
      <div class="control-bar__buttons">
        <div class="control-bar__button" :title="__('common.refresh')" @click="refresh" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="refresh"/>
        </div>
        <div class="control-bar__button" :title="__('common.add')" @click="addStellarObject" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/add.svg"
               alt="add"/>
        </div>
      </div>
    </div>
    <div class="scroll-horizontal-overflow">
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
      <data-table
          :records="stellarObjects"
          :fields="dataTableFields"
          :record-actions="recordActions"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :loading="loading"
          @editStellarObject="editStellarObject"
          @sort="sort"
          @deleteRecord="deleteRecord"
      />
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
      <edit-stellar-object ref="editStellarObject" @updated="refresh" @created="editStellarObject"/>
    </div>
  </div>
</template>

<script>
  import Swal from 'sweetalert2';
  import apiSecured from '../../api/secured';
  import DataTable from '../../components/DataTable';
  import AppHeader from '../../components/AppHeader';
  import { notificationsMixin } from '@/mixins/notifications';
  import { localizationMixin } from '@/mixins/localization';
  import { paginationMixin } from "@/mixins/pagination";
  import EditStellarObject from '../../components/Ingame/EditStellarObject';
  import { pageTitleMixin } from '@/mixins/pageTitle';

  export default {
    mixins: [notificationsMixin, localizationMixin, paginationMixin, pageTitleMixin],
    components: {
      AppHeader,
      DataTable,
      EditStellarObject
    },
    data: () => ({
      loading: false,
      stellarObjects: [],
      dataTableFields: [],
      recordActions: [],
    }),
    methods: {
      refreshDelayed(delay) {
        setTimeout(this.refresh, delay);
      },
      refresh(callback) {
        this.loading = true;
        let params = this.getPaginationParams();
        this.$router.push({ name: this.$route.name, query: params }).catch(err => {
        });
        apiSecured.get('/ingame/world/stellarobject', { params }).then(async (res) => {
          this.stellarObjects = res.data;
          await this.enrichRecords();
          this.updatePagination(res);
          this.loading = false;
          this.showInfoNotification(this.__('common.stellar_objects_loaded'));
          if (typeof callback === 'function') {
            callback();
          }
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error)
        });
      },
      async enrichRecords() {
        const parentIds = this.stellarObjects.filter(record => record.parentId !== null).map(record => record.parentId);
        if (parentIds.length > 0) {
          await apiSecured.get(`/ingame/world/stellarobject/(${parentIds.join(',')})`, {
            params: {
              fields: 'id,name'
            },
          }).then((res) => {
            this.stellarObjects = this.stellarObjects.map(record => {
              record.parent = res.data.find(resRecord => resRecord.id === record.parentId);
              record.parentName = record.parent ? record.parent.name : null;
              return record;
            });
            this.showInfoNotification('Parents have been reloaded');
          }).catch((error) => {
            this.showErrorNotifications(error)
          });
        }
        this.stellarObjects = this.stellarObjects.map(stellarObject => {
          stellarObject.position = {
            x: stellarObject.positionX,
            y: stellarObject.positionY,
            z: stellarObject.positionZ,
          };
          return stellarObject;
        });
      },
      addStellarObject() {
        this.$refs.editStellarObject.showCreate();
      },
      editStellarObject(stellarObject) {
        this.$refs.editStellarObject.showEdit(stellarObject);
      },
      deleteRecord(record) {
        Swal.fire({
          title: this.__('common.are_you_sure'),
          text: this.__('common.do_you_want_to_delete'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: this.__('common.yes_delete_it')
        }).then((result) => {
          if (result.value) {
            this.loading = true;
            let endpoint = '/ingame/world/stellarobject/' + record.id;
            apiSecured.post(endpoint).then((res) => {
              this.loading = false;
              this.showSuccessNotification(this.__('common.deleted'));
              this.refresh();
            }).catch((error) => {
              this.loading = false;
              this.showErrorNotifications(error);
              this.refresh();
            });
          }
        });
      },
    },
    created() {
      this.setPageTitle(this.__('entity.common.stellar_objects'));
      this.dataTableFields = [
        { name: "id", title: this.__('entity.common.id'), type: "default" },
        { name: "icon", title: this.__('entity.common.icon'), type: "image" },
        { name: "name", title: this.__('entity.common.title'), type: "default" },
        { name: "parentName", title: this.__('system'), type: "default", disableSorting: true },
        { name: "position", title: this.__('position'), type: "vec3", disableSorting: true },
        { name: "gravity", title: this.__('gravity'), type: "numeric" },
        { name: "hasAtmosphere", title: this.__('hasAtmosphere'), type: "boolean" },
        { name: "territories", title: this.__('territories'), type: "numeric" },
        { name: "biosphere", title: this.__('biosphere'), type: "default" },
        { name: "createdAt", title: this.__('entity.common.created_at'), type: "datetime" },
        { name: "updatedAt", title: this.__('entity.common.updated_at'), type: "datetime" }
      ];
      this.recordActions = [
        { title: this.__('component.ingame.edit_stellar_object.title'), icon: 'pencil', event: 'editStellarObject' },
        {
          title: this.__('view.ingame.stellar_objects.delete_stellar_object'),
          icon: 'bin',
          event: 'deleteRecord',
          restrictRoles: []
        }
      ];
      this.refresh();
    }
  }
</script>
