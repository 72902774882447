<template>
  <div class="edit-stellar-object">
    <my-dialog :show="show" @close="close" v-if="originalRecord && record">
      <div class="card">
        <div class="card__header">
          <div class="card__title">Edit Stellar Object</div>
          <div class="card__button" v-slashes @click="update" v-if="changedProperties.length > 0 && !loading"
               :title="__('common.save_changes')">
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/check.svg" alt="save"/>
          </div>
          <div class="card__button" @click="close" v-slashes>
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <div class="card__content">
          <progress-linear v-if="loading"/>
          <div class="layout layout--wrap" v-if="record">
            <div class="flex xs4">
              <h2>{{ __('common.edit_properties') }}</h2>
              <div class="layout layout--wrap">
                <div class="flex xs12">
                  <text-field
                      v-model="record.name"
                      :label="__('entity.common.name')"
                      :error-messages="errors.name"
                      maxlength="50"
                      :prepend-icon="isChanged('name') ? 'pencil' : null"
                      @input="registerChange('name')"
                  />
                </div>
                <div class="flex xs12">
                  <text-field
                      v-model="record.icon"
                      :label="__('entity.common.icon')"
                      :error-messages="errors.icon"
                      :prepend-icon="isChanged('icon') ? 'pencil' : null"
                      @input="registerChange('icon')"
                  />
                </div>
                <div class="flex xs12">
                  <label>{{ __('system') }}</label>
                  <select-reference
                      v-model="record.parentId"
                      :endpoint="'/ingame/world/stellarobject'"
                      :nothing-selected-message="__('common.no_stellar_object_selected')"
                      :prepend-icon="isChanged('parentId') ? 'pencil' : ''"
                      @input="registerChange('parentId')"
                  />
                </div>
                <div class="flex xs12 mt">
                  <text-area
                      v-model="record.description"
                      :label="__('entity.common.description')"
                      :error-messages="errors.description"
                      :prepend-icon="isChanged('description') ? 'pencil' : null"
                      @input="registerChange('description')"
                  />
                </div>


                <div class="flex xs12" v-for="stringProperty in stringProperties" :key="stringProperty">
                  <text-field
                      v-model="record[stringProperty]"
                      :label="__(stringProperty)"
                      :error-messages="errors[stringProperty]"
                      :prepend-icon="isChanged(stringProperty) ? 'pencil' : null"
                      @input="registerChange(stringProperty)"
                  />
                </div>


                <div class="flex xs12" v-for="numberProperty in numberProperties" :key="numberProperty">
                  <text-field
                      v-model="record[numberProperty]"
                      :label="__(numberProperty)"
                      :type="'number'"
                      :error-messages="errors[numberProperty]"
                      :prepend-icon="isChanged(numberProperty) ? 'pencil' : null"
                      @input="registerChange(numberProperty)"
                  />
                </div>
                <div class="flex xs12 mt" v-for="booleanProperty in booleanProperities" :key="booleanProperty">
                  <my-switch
                      v-model="record[booleanProperty]"
                      :label="__(booleanProperty)"
                      :append-icon="isChanged(booleanProperty) ? 'pencil' : null"
                      @input="registerChange(booleanProperty)"
                  />
                </div>
                <div class="flex xs12 mt">
                  <div class="btn btn--success" :loading="loading" :disabled="changedProperties.length === 0"
                       @click="update" v-slashes>{{ __('common.save_changes') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="flex xs8">
              <edit-ore-appearance ref="editOreAppearances" :stellar-object-id="record.id"></edit-ore-appearance>
            </div>
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
import { localizationMixin } from '@/mixins/localization';
import EditOreAppearance from "./StellarObject/EditOreAppearance";
import { editFormMixin } from "@/mixins/editForm";

export default {
  components: {
    EditOreAppearance,
  },
  mixins: [localizationMixin, editFormMixin],
  data: () => ({
    endpoint: '/ingame/world/stellarobject',
    stringProperties: [
      'biosphere',
      'classification',
      'discoveredBy',
      'habitability',
      'size',
      'systemZone',
      'type',
    ],
    numberProperties: [
      'antiGravMinAltitude',
      'atmosphericDensityAboveSurface',
      'atmosphericEngineMaxAltitude',
      'bodyId',
      'gm',
      'gravity',
      'fullAtmosphericDensityMaxAltitude',
      'noAtmosphericDensityAltitude',
      'numSatellites',
      'positionFromSun',
      'positionX',
      'positionY',
      'positionZ',
      'radius',
      'safeAreaEdgeAltitude',
      'spaceEngineMinAltitude',
      'surfaceArea',
      'surfaceAverageAltitude',
      'surfaceMaxAltitude',
      'surfaceMinAltitude',
      'territories',
      'waterLevel',
    ],
    booleanProperities: [
      'hasAtmosphere',
      'isSanctuary'
    ]
  }),
}
</script>
