<template>
    <div class="edit-ore-appearances position-relative" :key="rerenderKey">
        <progress-linear v-if="loading" :indeterminate="true"/>
        <h2>{{__('component.ingame.edit_stellar_object.edit_ore_appearances.title')}}</h2>
        <template v-if="stellarObjectId !== null">
            <table class="editable-table">
                <tr v-for="(oreAppearance, index) in oreAppearances" :key="'ore_appearance_'+index">
                    <td v-if="oreAppearance.ore"><img :src="oreAppearance.ore.icon" alt="-"/></td>
                    <td v-if="oreAppearance.ore">{{ oreAppearance.ore.name }}</td>
                    <td>
                        <text-field
                                v-model="oreAppearance.highestAltitudeFromSurface"
                                :label="__('entity.ingame.ore_appearance.highest_altitude_from_surface')"
                                :type="'number'"
                                :hide-details="true"
                                :prepend-icon="getPrependIcon(oreAppearance)"
                                @input="registerChange(oreAppearance)"
                        />
                    </td>
                    <td>
                        <text-field
                                v-model="oreAppearance.lowestAltitudeFromSurface"
                                :label="__('entity.ingame.ore_appearance.lowest_altitude_from_surface')"
                                :type="'number'"
                                :hide-details="true"
                                :prepend-icon="getPrependIcon(oreAppearance)"
                                @input="registerChange(oreAppearance)"
                        />
                    </td>
                    <td class="editable-table__button" @click="toggleDeleted(oreAppearance)">
                        <img src="https://cdn.hyperion-corporation.de/ui/svgs/bin.svg" alt="x"/>
                    </td>
                </tr>
            </table>
            <div class="layout">
                <div class="flex xs6 mt">
                    <div class="btn btn--success" :loading="loading" :disabled="!hasChanges()"
                         @click="saveOreAppearances"
                         v-slashes>{{ __('common.save_changes') }}
                    </div>
                </div>
                <div class="flex xs6 mt">
                    <div class="btn btn--info" :loadding="loading" @click="showOreBrowser" v-slashes>
                        {{__('component.ingame.edit_stellar_object.edit_ore_appearances.add_ore_appearances')}}
                    </div>
                </div>
            </div>
        </template>
        <p v-else>{{__('component.ingame.edit_stellar_object.edit_ore_appearances.save_first')}}</p>
        <ore-browser ref="oreBrowser" @oreSelected="addOre"/>
    </div>
</template>

<script>
  import apiSecured from '../../../api/secured';
  import {notificationsMixin} from '../../../mixins/notifications';
  import {rerenderMixin} from "../../../mixins/rerender";
  import {localizationMixin} from '../../../mixins/localization';
  import OreBrowser from "../Common/OreBrowser";

  export default {
    components: {OreBrowser},
    mixins: [notificationsMixin, rerenderMixin, localizationMixin],
    data: () => ({
      loading: false,
      oreAppearances: [],
      inProgress: 0,
      rerenderKey: 'no-set'
    }),
    props: ['stellarObjectId'],
    watch: {
      stellarObjectId() {
        this.refresh();
      }
    },
    created() {
      this.refresh();
    },
    methods: {
      hasChanges() {
        return this.oreAppearances.filter((oreAppearance) => {
          return this.isNew(oreAppearance) || this.isChanged(oreAppearance) || this.isDeleted(oreAppearance);
        }).length > 0;
      },
      refresh() {
        this.loading = true;
        this.oreAppearances = [];
        if (this.stellarObjectId === null) {
          this.loading = false;
          return;
        }
        apiSecured.get('/ingame/world/oreappearance/by-stellar-object/' + this.stellarObjectId).then(async (res) => {
          this.oreAppearances = res.data;
          await this.enrichRecords();
          this.loading = false;
          this.showInfoNotification(this.__('common.ore_appearances_loaded'));
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
      },
      async enrichRecords() {
        const oreIds = this.oreAppearances.filter(record => record.oreId !== null).map(record => record.oreId);
        if (oreIds.length > 0) {
          await apiSecured.get(`/ingame/items/item/(${oreIds.join(',')})`, {
            params: {
              fields: 'id,icon,name'
            },
          }).then((res) => {
            this.oreAppearances = this.oreAppearances.map(record => {
              record.ore = res.data.find(resRecord => resRecord.id === record.oreId);
              return record;
            });
            this.showInfoNotification('Ores have been reloaded');
          }).catch((error) => {
            this.showErrorNotifications(error)
          });
        }
      },
      registerChange(oreAppearance) {
        if (!this.isNew(oreAppearance)) {
          oreAppearance.changed = true;
          // this.forceRerender();
        }
      },
      isNew(oreAppearance) {
        return oreAppearance.hasOwnProperty('new') && oreAppearance.new;
      },
      isChanged(oreAppearance) {
        return oreAppearance.hasOwnProperty('changed') && oreAppearance.changed;
      },
      isDeleted(oreAppearance) {
        return oreAppearance.hasOwnProperty('deleted') && oreAppearance.deleted;
      },
      showOreBrowser() {
        this.$refs.oreBrowser.showDialog();
      },
      addOre(ore) {
        if (!this.oreAppearances.find((oreAppearance) => oreAppearance.oreId === ore.id)) {
          this.oreAppearances.push(
            {
              stellarObjectId: this.stellarObjectId,
              oreId: ore.id,
              ore: ore,
              highestAltitudeFromSurface: 0,
              lowestAltitudeFromSurface: 0,
              new: true
            }
          )
        }
      },
      toggleDeleted(oreAppearance) {
        if (this.isNew(oreAppearance)) {
          this.oreAppearances = this.oreAppearances.filter((fOreAppearance) => fOreAppearance !== oreAppearance);
          this.forceRerender();
        } else {
          oreAppearance.deleted = !this.isDeleted(oreAppearance);
          this.forceRerender();
        }
      },
      getPrependIcon(oreAppearance) {
        if (this.isDeleted(oreAppearance)) {
          return 'bin'
        } else if (this.isChanged(oreAppearance)) {
          return 'pencil'
        } else if (this.isNew(oreAppearance)) {
          return 'asterisk'
        }
        return null;
      },
      saveOreAppearances() {
        this.oreAppearances.forEach((oreAppearance) => {
          let endpoint = '/ingame/world/oreappearance';
          let event = null;
          let method = null;
          let payload = null;
          if (this.isNew(oreAppearance)) {
            event = 'oreAppearanceCreated';
            method = apiSecured.post;
            payload = {
              stellarObjectId: this.stellarObjectId,
              oreId: oreAppearance.oreId,
              lowestAltitudeFromSurface: oreAppearance.lowestAltitudeFromSurface,
              highestAltitudeFromSurface: oreAppearance.highestAltitudeFromSurface
            }
          } else if (this.isChanged(oreAppearance)) {
            endpoint = '/ingame/world/oreappearance/' + oreAppearance.id;
            event = 'oreAppearanceUpdated';
            method = apiSecured.patch;
            payload = [
              {
                op: 'replace',
                path: `/lowestAltitudeFromSurface`,
                value: oreAppearance.lowestAltitudeFromSurface
              },
              {
                op: 'replace',
                path: `/highestAltitudeFromSurface`,
                value: oreAppearance.highestAltitudeFromSurface
              },
            ];
          } else if (this.isDeleted(oreAppearance)) {
            endpoint = '/ingame/world/oreappearance/' + oreAppearance.id;
            event = 'oreAppearanceDeleted';
            method = apiSecured.delete;
            payload = {};
          } else {
            return;
          }
          this.loading = true;
          this.inProgress++;
          method(endpoint, payload).then((res) => {
            this.showSuccessNotification(this.__('common.saved'));
            this.$emit(event);
            this.inProgress--;
            if (this.inProgress <= 0) {
              this.loading = false;
              this.inProgress = 0;
              this.refresh()
            }
          }).catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error);
            this.inProgress--;
            if (this.inProgress <= 0) {
              this.loading = false;
              this.inProgress = 0;
              this.refresh()
            }
          });
        })
      }
    }
  }
</script>