<template>
  <div class="ore-browser">
    <my-dialog :show="show" max-width="400px">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{__('component.ingame.common.ore_browser.title')}}</div>
          <div class="card__button" @click="close" v-slashes>
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <progress-linear v-if="loading" :indeterminate="true"/>
        <div class="card__content">
          <text-field
              v-model="searchTerm"
              :label="__('common.search_term')"
              maxlength="50"
              :hide-details="true"
              @keyup:enter="refresh"
          />
          <div class="selection-list" v-if="ores.length > 0">
            <div class="selection-list__item" v-for="ore in ores" v-slashes @click="emitSelection(ore)">
              <div class="selection-list__item-icon">
                <img :src="ore.icon" alt="-"/>
              </div>
              <div class="selection-list__item-title">
                {{ ore.name }}
              </div>
            </div>
          </div>
          <p v-else>{{__('component.ingame.common.ore_browser.select_ore_first')}}</p>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
  import apiSecured from '../../../api/secured';
  import {notificationsMixin} from '../../../mixins/notifications';
  import {localizationMixin} from '../../../mixins/localization';

  export default {
    mixins: [notificationsMixin, localizationMixin],
    data: () => ({
      show: false,
      loading: false,
      oreGroup: null,
      ores: [],
      searchTerm: ''
    }),
    methods: {
      async refresh() {
        this.loading = true;
        await apiSecured.get('/ingame/items/group/by-name/ore').then(res => {
          this.oreGroup = res.data;
        }).catch(error => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
        if (this.oreGroup !== null) {
          await apiSecured.get('/ingame/items/item', {
            params: {
              searchQuery: this.searchTerm,
              groupIds: this.oreGroup.id,
              recursiveGroupsFilter: true,
              pageSize: 50,
              orderBy: 'name asc'
            }
          }).then((res) => {
            this.ores = res.data;
            this.loading = false;
            this.showInfoNotification(this.__('common.ores_loaded'));
          }).catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error);
          });
        }
      },
      showDialog() {
        this.show = true;
        this.refresh();
      },
      close() {
        this.show = false;
      },
      emitSelection(ore) {
        this.$emit('oreSelected', ore);
        this.show = false;
      }
    }
  }
</script>